html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

iframe#typeform-full {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border: 0;
}
